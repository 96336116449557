import { useRef } from 'react';

import { getRealIndex } from '../getRealIndex';
import { useTopImpactStoriesMobileContext } from '../TopImpactStoriesMobileContext';
import { useScrollToStory } from '../useScrollToStory';

export function useSwipeOrScroll() {
	const {
		state: { containerRef, storyRefs, suppressScrollRef },
		dispatch,
	} = useTopImpactStoriesMobileContext();
	const lastJumpedFromCloneRef = useRef<number | null>(null);
	const scrollToStory = useScrollToStory();
	const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);

	return () => {
		const container = containerRef.current;

		if (suppressScrollRef.current || !container || container.clientWidth === 0) return;
		if (scrollTimeoutRef.current) clearTimeout(scrollTimeoutRef.current);

		const containerCenter = container.scrollLeft + container.offsetWidth / 2;

		let closestIndex = 0;
		let smallestDistance = Infinity;

		storyRefs.current.forEach((el, index) => {
			if (!el) return;

			const itemCenter = el.offsetLeft + el.offsetWidth / 2;
			const distance = Math.abs(containerCenter - itemCenter);

			if (distance < smallestDistance) {
				smallestDistance = distance;
				closestIndex = index;
			}
		});

		if (lastJumpedFromCloneRef.current !== null) {
			if (closestIndex <= 2 || closestIndex >= 8) {
				return;
			}
			lastJumpedFromCloneRef.current = null;
		}

		const realIndex = getRealIndex(closestIndex);
		if (realIndex === closestIndex) {
			dispatch({ type: 'SET_CURRENT_STORY_INDEX', payload: realIndex });
		}

		scrollTimeoutRef.current = setTimeout(() => {
			if (realIndex !== closestIndex) {
				scrollToStory(realIndex, { behavior: 'auto', isJumpingFromClone: true });
				lastJumpedFromCloneRef.current = closestIndex;
				dispatch({ type: 'SET_CURRENT_STORY_INDEX', payload: realIndex });
			}
		}, 150);
	};
}
