import { useEffect } from 'react';

import type { TopStories } from '../../../shared/hooks/useTopImpactStories';
import { useTopImpactStories } from '../../../shared/hooks/useTopImpactStories';
import { useTopImpactStoriesMobileContext } from '../TopImpactStoriesMobileContext';
import type { CarouselStories } from '../TopImpactStoriesMobileContext/types';
import { useArrowClick } from '../useArrowClick';
import { useSwipeOrScroll } from '../useSwipeOrScroll';

type Result = ModelHookResult<
	{
		carouselStories: CarouselStories;
		currentStoryIndex: number;
		containerRef: React.MutableRefObject<HTMLDivElement | null>;
		storyRefs: React.MutableRefObject<Array<HTMLDivElement | null>>;
	},
	{
		trackStoryClick: (url: string) => void;
		genDataTestId: (key: number, component: string) => string;
		onStoryFocus: (targetIndex: number) => void;
		onScroll: () => void;
	}
>;

const getCarouselStories = (topStories: TopStories[]) => {
	// Implementing carousel looping with clones.
	// We need 3 clones on either end to allow smooth centered scrolling.
	// At the `md` breakpoint, we can see 2 extra items on each side of the centered one.
	// Without a 3rd clone, scrolling to the 5th item would leave an empty gap at the end.
	return [...topStories.slice(-3), ...topStories, ...topStories.slice(0, 3)].map((story, index) => ({
		...story,
		key: index + 1,
		tabIndex: index <= 2 || index >= 8 ? -1 : 0,
		ariaHidden: index <= 2 || index >= 8,
	}));
};

export function useTopImpactStoriesMobile(): Result {
	const {
		data: { topStories },
		actions: { genDataTestId, trackStoryClick },
	} = useTopImpactStories();

	const {
		state: { currentStoryIndex, containerRef, storyRefs, carouselStories: currentCarouselStories },
	} = useTopImpactStoriesMobileContext();

	const { dispatch } = useTopImpactStoriesMobileContext();

	const carouselStories = getCarouselStories(topStories);

	const handleArrowClick = useArrowClick();

	useEffect(() => {
		if (currentCarouselStories.length === 0 && carouselStories.length > 0) {
			dispatch({ type: 'SET_CAROUSEL_STORIES', payload: carouselStories });
			handleArrowClick(3);
		}
	}, [carouselStories, currentCarouselStories.length, dispatch, handleArrowClick]);

	const handleSwipeOrScroll = useSwipeOrScroll();

	return {
		data: {
			currentStoryIndex,
			carouselStories,
			containerRef,
			storyRefs,
		},
		actions: {
			trackStoryClick,
			genDataTestId,
			onStoryFocus: (targetIndex: number) => handleArrowClick(targetIndex),
			onScroll: handleSwipeOrScroll,
		},
	};
}
