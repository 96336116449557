import type { Action, TopImpactStoriesMobileData } from './types';

export const topImpactStoriesMobileReducer = (state: TopImpactStoriesMobileData, action: Action) => {
	switch (action.type) {
		case 'SET_CURRENT_STORY_INDEX': {
			return { ...state, currentStoryIndex: action.payload };
		}
		case 'SET_CAROUSEL_STORIES': {
			return { ...state, carouselStories: action.payload };
		}
		default: {
			return state;
		}
	}
};
