import { createContext, useContext } from 'react';

import type { Action, TopImpactStoriesMobileData } from './types';

type TopImpactStoriesMobileContextType = { state: TopImpactStoriesMobileData; dispatch: (action: Action) => void };

export const TopImpactStoriesMobileContext = createContext<TopImpactStoriesMobileContextType | undefined>(undefined);

export const useTopImpactStoriesMobileContext = () => {
	const context = useContext(TopImpactStoriesMobileContext);

	if (!context)
		throw new Error('useTopImpactStoriesMobileContext must be used within a TopImpactStoriesMobileProvider');
	return context;
};
