import { useEffect, useState } from 'react';

import { useTopImpactStoriesMobileContext } from '../../../../hooks/TopImpactStoriesMobileContext';
import { useArrowClick } from '../../../../hooks/useArrowClick';

type Result = ModelHookResult<
	{
		currentStorySummary: string;
		isSummaryFading: boolean;
	},
	{
		onLeftClick: () => void;
		onRightClick: () => void;
	}
>;

export function useFooter(): Result {
	const {
		state: { carouselStories, currentStoryIndex },
	} = useTopImpactStoriesMobileContext();

	const [currentStorySummary, setCurrentStorySummary] = useState(carouselStories[3]?.summary || '');
	const [isSummaryFading, setIsFading] = useState(false);

	const handleArrowClick = useArrowClick();

	useEffect(() => {
		if (!carouselStories[currentStoryIndex]?.summary) return () => {};

		setIsFading(true);

		const timeout = setTimeout(() => {
			setCurrentStorySummary(carouselStories[currentStoryIndex].summary);
			setIsFading(false);
		}, 300);

		return () => clearTimeout(timeout);
	}, [carouselStories, currentStoryIndex]);

	return {
		data: {
			currentStorySummary,
			isSummaryFading,
		},
		actions: {
			onLeftClick: () => handleArrowClick(currentStoryIndex - 1),
			onRightClick: () => handleArrowClick(currentStoryIndex + 1),
		},
	};
}
