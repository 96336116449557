import { getRealIndex } from '../getRealIndex';
import { useTopImpactStoriesMobileContext } from '../TopImpactStoriesMobileContext';
import { useScrollToStory } from '../useScrollToStory';

export function useArrowClick() {
	const {
		state: { suppressScrollRef },
		dispatch,
	} = useTopImpactStoriesMobileContext();

	const scrollToStory = useScrollToStory();

	return (targetIndex: number) => {
		suppressScrollRef.current = true;

		scrollToStory(targetIndex);

		if (targetIndex <= 2 || targetIndex >= 8) {
			const realIndex = getRealIndex(targetIndex);
			dispatch({ type: 'SET_CURRENT_STORY_INDEX', payload: realIndex });

			setTimeout(() => {
				scrollToStory(realIndex, { behavior: 'auto' });
				setTimeout(() => {
					suppressScrollRef.current = false;
				}, 50);
			}, 400);
		} else {
			dispatch({ type: 'SET_CURRENT_STORY_INDEX', payload: targetIndex });

			setTimeout(() => {
				suppressScrollRef.current = false;
			}, 400);
		}
	};
}
