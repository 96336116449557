import type { Locale } from '@change-corgi/config/locales';
import type { UtilityContext } from '@change-corgi/core/react/utilityContext';
import { FCM_CAPYBARA_HOMEPAGE_V3_CONTENT } from '@change-corgi/fcm/configs';

import type {
	HomepageTopImpactStoriesDataQuery,
	HomepageTopImpactStoriesDataQueryVariables,
} from './getTopImpactStories.graphql';
import { HomepageTopImpactStoriesData } from './getTopImpactStories.graphql';

type Props = {
	locale: string;
	utilityContext: UtilityContext;
};

export type ImpactStory = {
	petition: {
		id: string;
		slug: string;
		signatureCount: string;
	};
	badgeTitle: string;
	summary: string;
	imageUrl: string;
	testimonialUrl?: string;
};

export async function getTopImpactStories({
	locale,
	utilityContext: {
		gql: { fetch },
		fcm,
		errorReporter,
	},
}: Props): Promise<ImpactStory[]> {
	const homepageV3ContentFcm = await fcm.get({
		homepageV3Content: FCM_CAPYBARA_HOMEPAGE_V3_CONTENT,
	});

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const topImpactStories = homepageV3ContentFcm.homepageV3Content[locale as Locale]?.topImpactStories ?? [];

	const impactStoryPromises = topImpactStories.map(async (story) => {
		const { data } = await fetch<HomepageTopImpactStoriesDataQuery, HomepageTopImpactStoriesDataQueryVariables>({
			query: HomepageTopImpactStoriesData,
			variables: { petitionId: story.petitionId },
			rejectOnError: false,
		});

		if (!data?.petition) {
			void errorReporter.report(new Error(`No data returned for impact story petition ${story.petitionId}`));
			return null;
		}

		return {
			petition: {
				id: story.petitionId,
				slug: data.petition.slug,
				signatureCount: data.petition.signatureState.signatureCount.displayedLocalized,
			},
			badgeTitle: story.badgeTitle || (data.petition.status === 'VICTORY' ? 'Victory!' : 'Trending!'),
			summary: story.summary,
			imageUrl: story.imageUrl,
			...(story.testimonialUrl && { testimonialUrl: story.testimonialUrl }),
		};
	});

	const v3TopImpactStories = (await Promise.all(impactStoryPromises)).filter(Boolean);

	return v3TopImpactStories;
}
