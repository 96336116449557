import type { ReactNode } from 'react';
import { useReducer } from 'react';

import { topImpactStoriesMobileReducer } from './reducer';
import { TopImpactStoriesMobileContext } from './useTopStoriesMobileContext';

const defaultState = {
	carouselStories: [],
	containerRef: { current: null },
	storyRefs: { current: [] },
	currentStoryIndex: 3,
	suppressScrollRef: { current: false },
};

export const TopImpactStoriesMobileProvider = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(topImpactStoriesMobileReducer, defaultState);
	return (
		<TopImpactStoriesMobileContext.Provider value={{ state, dispatch }}>
			{children}
		</TopImpactStoriesMobileContext.Provider>
	);
};
