import { FocusRing, Text } from '@change/tomato-core-ui';
import { ArrowLeftIcon, ArrowRightIcon } from '@change/tomato-icons';

import { useFooter } from './hooks/useFooter';

type Props = {
	dataTestId: string;
};

export const Footer: React.FC<Props> = ({ dataTestId }) => {
	const {
		data: { currentStorySummary, isSummaryFading },
		actions: { onLeftClick, onRightClick },
	} = useFooter();

	return (
		<div className="flex h-28 items-center justify-center gap-4">
			<FocusRing focusRingClass="outline-2">
				<button type="button" onClick={onLeftClick} aria-label="Previous story" data-testid="left-arrow">
					<ArrowLeftIcon className="text-content-accent" size="large" />
				</button>
			</FocusRing>

			<div className="relative flex h-full w-60 items-center justify-center" aria-hidden tabIndex={-1}>
				<Text
					as="p"
					className={`line-clamp-4 overflow-hidden text-ellipsis text-center transition-opacity duration-300 ${isSummaryFading ? 'opacity-0' : 'opacity-100'}`}
					data-testid={dataTestId}
				>
					{currentStorySummary}
				</Text>
			</div>
			<FocusRing focusRingClass="outline-2">
				<button type="button" onClick={onRightClick} aria-label="Next story" data-testid="right-arrow">
					<ArrowRightIcon className="text-content-accent" size="large" />
				</button>
			</FocusRing>
		</div>
	);
};
